export const checksums = {
  "blog": "v3.3.0--XD115YUQNIoWujHZWdTA9eJzv7hR5JNDMnVVEWYSplg",
  "page": "v3.3.0--EQ5zuzpcOGijMnX12fhZMNtHflXJqzgonZVu-M0hX-g"
}
export const checksumsStructure = {
  "blog": "QsIQTS2XkJIgYv54KKwNBx9rHcC1Opi7xMBxga4rgdk",
  "page": "ZJmgK8QpiovvaINnv71xswQP_xqBqR1Xz1V_zRJIfQk"
}

export const tables = {
  "blog": "_content_blog",
  "page": "_content_page",
  "info": "_content_info"
}

export default {
  "blog": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "extraTitle": "string",
      "category": "string",
      "image": "json",
      "cover": "string",
      "order": "number",
      "section_recommended": "boolean",
      "section_popular": "boolean",
      "section_media": "boolean",
      "section_blog": "boolean",
      "section_catalog": "boolean",
      "date": "string",
      "author": "string"
    }
  },
  "page": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json"
    }
  },
  "info": {
    "type": "data",
    "fields": {}
  }
}